body {
    background-color: #E5E8C9;
    margin: 0;
}

@font-face {
    font-family: 'Billabong';
    src: url('./assets/Billabong\ 400.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }